<template>
  <div>
    <member-breadcrumb :title="title"/>
    <div>
      <el-form :inline="true" :model="query" class="pull-left" onsubmit="return false">


        <el-form-item label="项目类型">
          <el-select v-model="query.projectType" placeholder="请选择">
            <el-option
                    key=""
                    label="全部"
                    value="">
            </el-option>
            <el-option
                    v-for="(name,value) in ProjectTypeMap"
                    :key="value"
                    :label="name"
                    :value="value">
            </el-option>
          </el-select>

        </el-form-item>

        <el-form-item label="状态">
          <el-select v-model="query.flag" placeholder="请选择">
            <el-option
                    key=""
                    label="全部"
                    value="">
            </el-option>
            <el-option
                    v-for="(name,value) in ProjectFlagMap"
                    :key="value"
                    :label="name"
                    :value="value">
            </el-option>
          </el-select>

        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="pagingSearch">查询</el-button>
        </el-form-item>
      </el-form>

      <el-button type="danger" class="pull-right" @click="$router.push({name:'projectCreate'})">提交项目需求</el-button>
      <div class="clear"></div>
    </div>


    <el-table
            :data="data.list"
            border
            class="mt10"
            @sort-change="elSort"
            :default-sort="{prop: 'id', order: 'descending'}"
            style="width: 100%">

      <el-table-column
              prop="id"
              align="center"
              label="项目编号"
              sortable="custom" :sort-orders="[ 'descending','ascending']"
              width="105"
      />


      <el-table-column
              prop="type"
              align="center"
              label="项目类型"
              width="85"
      >
        <template slot-scope="scope">
          {{ProjectTypeMap[scope.row.projectType.toString()]}}
        </template>
      </el-table-column>

      <el-table-column
              prop="projectName"
              header-align="center"
              align="left"
              label="项目名称"
      />


      <el-table-column
              prop="type"
              align="center"
              label="项目状态"
              width="85"
      >
        <template slot-scope="scope">
          {{ProjectFlagMap[scope.row.flag.toString()]}}
        </template>
      </el-table-column>


      <el-table-column
              prop="price"
              align="right"
              header-align="center"
              width="85"
              label="项目报价"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.price>0"> {{scope.row.price/ 100}}</span>
          <span v-else>待报价</span>
        </template>
      </el-table-column>

      <el-table-column
              prop="createdAt"
              align="center"
              label="创建时间"
              width="160"

      >
        <template slot-scope="scope">
          {{dateUtil.format(scope.row.createdAt)}}
        </template>
      </el-table-column>


      <el-table-column
              align="left"
              header-align="center"
              label="操作"
              width="94"
      >
        <template slot-scope="scope">
          <router-link :to="{name:'projectDetail', params:{id:scope.row.id}}">查看</router-link>

          <router-link v-if="scope.row.flag===0" class="ml10" :to="{name:'projectUpdate', params:{id:scope.row.id}}">编辑</router-link>
          <router-link v-if="scope.row.flag>10 && scope.row.moneyPaid<scope.row.price" class="ml10" :to="{name:'createPayOrder', params:{id:scope.row.id}}">付款</router-link>





        </template>
      </el-table-column>

    </el-table>
    <my-pagination :pager="data" @goPage="pagingTurn"></my-pagination>
  </div>
</template>

<script>
  import urlUtil from 'tdw-url-util'
  import dateUtil from 'tdw-date-util'
  import MyPagination from '../common/MyPagination'
  import ProjectFlagMap from './ProjectFlagMap'
  import ProjectTypeMap from './ProjectTypeMap'
  import MemberBreadcrumb from '../common/MemberBreadcrumb'

  export default {
    name: 'ProjectList',
    components: {MemberBreadcrumb, MyPagination},
    data () {
      return {
        title: '我的项目',
        usePagingPlugin: true,
        query: {
          pageNum: urlUtil.getPara('pageNum', 1),
          pageSize: 20,
          orderColumn: 'id',
          orderType: 'desc',
          projectType: urlUtil.getPara('projectType'),
          flag: urlUtil.getPara('flag'),
        },
        queryUrl: '/project/list.do',
        data: {list: []},
        dateUtil,
        ProjectFlagMap,
        ProjectTypeMap
      }
    }
  }
</script>

<style scoped>

</style>